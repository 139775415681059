<template>
  <div>
    <h6>Presentasi Realisasi</h6>
    <br />

    <div class="row justify-content-end">
      <v-text-field
        outlined
        dense
        prepend-inner-icon="mdi-magnify"
        placeholder="Cari..."
        style="max-width: 300px"
        v-model="optionsTable.search"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="data_realisasi_persentasi"
      :options.sync="optionsTable"
      :search="optionsTable.search"
      class="table-striped"
    >
      <template v-slot:[`item.persentase`]="{ item }">
        {{ item.persentase }}%
      </template>
      <template v-slot:[`item.persentase_terkecil`]="{ item }">
        {{ item.persentase_terkecil }}%
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DataRealisasi",
  data() {
    return {
      headers: [
        { text: "Seri", value: "seri" },
        { text: "HS Code", value: "hs" },
        { text: "Uraian", value: "uraian" },
        { text: "Negara Asal", value: "negara_asal" },
        { text: "Pelabuhan Tujuan", value: "pelabuhan_tujuan" },
        { text: "Kuota", value: "kuota" },
        { text: "Satuan", value: "satuan" },
        { text: "Kuota Terkecil", value: "kuota_terkecil" },
        { text: "Satuan Terkecil", value: "satuan_terkecil" },
        { text: "Realisasi", value: "volume" },
        { text: "Persentase", value: "persentase" },
        { text: "Realisasi Terkecil", value: "volume_terkecil" },
        { text: "Percentase Terkecil", value: "persentase_terkecil" },
        // { text: "File", value: "upload_id" },
      ],
      optionsTable: {
        page: 1,
        itemsPerPage: 5,
        search: "",
      },
    };
  },
  computed: {
    data_realisasi_persentasi() {
      return this.$store.state.realisasi.data_realisasi_persentasi;
    },
  },
  created() {
    this.$store.dispatch(
      "GetDataRealisasiPersentasi",
      this.$route.params.id_ijin
    );
  },
};
</script>
